import React from 'react';

const PromoSection = () => {
  return (
    <section className="promo">
      <div className="container">
        <h2 className="promo__title promo__title--primary">Smarter finances</h2>
        <h2 className="promo__title promo__title--secondary">
          with bunq and Flow
        </h2>
        <div className="promo__text">
          Manage your money better by combining the features of bunq and the
          power of Flow.
        </div>
        <a
          href="https://www.getbunq.app/2G4NJSR/225JFQ/?uid=32"
          rel="noreferrer noopener"
          target="_blank"
          className="btn btn--primary"
        >
          Open a bunq account
        </a>
        <small className="promo__small">On your phone, in 5 minutes</small>
      </div>
    </section>
  );
};

export default PromoSection;
