import React from 'react';

const AppSection = () => {
  return (
    <section className="app">
      <div className="container">
        <h2 className="app__title">Start automating your money today</h2>
        <div className="app__text">
          Get started by opening a bunq account, then link it to Flow.
        </div>
        <a
          href="https://www.getbunq.app/2G4NJSR/225JFQ/?uid=32"
          rel="noreferrer noopener"
          target="_blank"
          className="btn btn--primary"
        >
          Open a bunq account
        </a>
        <small className="app__small">On your phone, in 5 minutes</small>
      </div>
    </section>
  );
};

export default AppSection;
