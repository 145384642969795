import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import CoworkingSection from '../components/bunq-deal/CoworkingSection';
import RulesSection from '../components/bunq-deal/RulesSection';
import ScreensSection from '../components/bunq-deal/ScreensSection';
import AppSection from '../components/bunq/AppSection';
import PromoSection from '../components/bunq/PromoSection';

const Bunq = (page: PageProps) => {
  return (
    <Layout>
      <Seo page={page} title="bunq + Flow = ❤" />
      <main className="page-bunq">
        <PromoSection />
        <ScreensSection />
        <CoworkingSection />
        <RulesSection />
        <AppSection />
      </main>
    </Layout>
  );
};

export default Bunq;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
